<template>
    <ion-page>
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                                @click="$router.go(-1)"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">CHUYỂN ĐỔI LỆNH</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :scroll-events="false">
            <div style="position: relative">
                <DxValidationGroup ref="formValidation">
                    <div class="row mt-2 align-center justify-center">
                        <DxDateBox
                            v-model="NgayLoc"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            styling-mode="outlined"
                            height="36px"
                            width="188px"
                            placeholder="Chọn ngày lọc"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule message="Ngày lọc không được bỏ trống!" />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                        </DxDateBox>

                        <DxButton
                            text="Lọc"
                            type="normal"
                            styling-mode="outlined"
                            icon="mdi mdi-magnify"
                            hint="Lọc"
                            class="ml-2"
                            @click="LocLenh()"
                        />
                    </div>
                </DxValidationGroup>

                <div class="row pb-5">
                    <DxList
                        :data-source="DanhSachLenh"
                        height="calc(100% - 8px)"
                        page-load-mode="scrollBottom"
                        key-expr="id"
                        :hoverStateEnabled="false"
                        :focusStateEnabled="false"
                        :activeStateEnabled="false"
                        :pullRefreshEnabled="true"
                        no-data-text="Không có dữ liệu!"
                        class="list-lenh-style"
                    >
                        <template #item="{ data: item }">
                            <FrameItemVue
                                :item="item"
                                @ChonLenhChuyen="ChonLenhChuyen"
                                @XemBanTheHienLenh="XemBanTheHienLenh"
                            />
                            <!-- :key="`${componentKey}-1`" -->
                        </template>
                    </DxList>
                </div>

                <!-- <div
                    class="row justify-space-between pa-2"
                    style="
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        border-top: 1px solid rgb(218, 220, 224);
                    "
                >
                    <DxButton
                        text="Hủy"
                        type="default"
                        styling-mode="text"
                        @click="$router.push('/tabs/LenhDienTu')"
                    />
                    <DxButton
                        text="Xác nhận"
                        type="default"
                        styling-mode="contained"
                        @click="onClick(event)"
                    />
                </div> -->
            </div>
        </ion-content>

        <!-- ====================================Bản thể hiện lệnh ========================================= -->
        <PopupBanTheHienLenhVue
            :dialog="dialogBanTheHienLenh"
            :guidLenh="LenhDangChon.guidLenh"
            :laLenhGiay="LenhDangChon.laLenhGiay"
            :danhSachLenhGiay="LenhDangChon.danhSachBanTheHien || []"
            @closes="
                () => {
                    dialogBanTheHienLenh = false;
                }
            "
        />

        <!-- Xác nhận chuyển lệnh -->
        <PopupVue
            height="auto"
            v-model:dialog="dialogXacNhanChuyenLenh"
            title=""
            buttonTextLeft="Hủy"
            class="popup-padding-8"
            @close="XacNhanChuyenLenh"
            heightScrollView="auto"
        >
            <template #content>
                <ThongBaoVue
                    :message="ParamThongBao.message"
                    :state="ParamThongBao.state"
                    :title="ParamThongBao.title"
                />
            </template>
        </PopupVue>

        <!-- Danh sách vé chuyển lệnh -->
        <PopupVue
            v-model:dialog="dialogDanhSachVeChuyenLenh"
            height="auto"
            title=""
            :fullWidth="false"
            :column="false"
            class="popup-padding-bottom-8"
            buttonTextLeft="Hủy"
            @close="XacNhanChuyenLenh"
            heightScrollView="auto"
        >
            <template #content>
                <PopupDanhSachVeChuyenLenhVue
                    :DanhSachHanhKhachMuaVe="DanhSachHanhKhachMuaVe"
                    v-model:DanhSachDonHang="DanhSachDonHang"
                />
            </template>
        </PopupVue>
    </ion-page>
</template>

<script>
import { DxButton, DxDateBox, DxList, DxValidator } from "devextreme-vue/";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { IonContent, IonHeader, IonPage, onIonViewWillLeave } from "@ionic/vue";
import PopupBanTheHienLenhVue from "../components/PopupBanTheHienLenh.vue";
import FrameItemVue from "./components/FrameItem.vue";
import PopupVue from "../../../components/_Common/Popup.vue";
import ThongBaoVue from "../../../components/_Common/ThongBao.vue";
import PopupDanhSachVeChuyenLenhVue from "./components/PopupDanhSachVeChuyenLenh";
import { mapState } from "vuex";

export default {
    components: {
        DxButton,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        IonPage,
        IonContent,
        IonHeader,
        PopupBanTheHienLenhVue,
        FrameItemVue,
        DxList,
        PopupVue,
        ThongBaoVue,
        PopupDanhSachVeChuyenLenhVue,
    },
    data() {
        return {
            NgayLoc: new Date(),
            DanhSachLenh: [],
            DanhSachHanhKhachMuaVe: [],
            DanhSachDonHang: [],
            dialogBanTheHienLenh: false,
            LenhDangChon: {},
            MaxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            MinDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            dialogDanhSachVeChuyenLenh: false,
            dialogXacNhanChuyenLenh: false,
            ParamThongBao: {
                state: "Warning",
                title: "Xác nhận chuyển đổi lệnh",
                message: "",
            },
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
    },
    methods: {
        async LocLenh() {
            try {
                let query = {
                    timKiem: null,
                    idLuongTuyen: null,
                    ngayXuatBenKeHoach: this.$Helper.getStartDay(this.NgayLoc),
                    danhSachGioXuatBen: [],
                };
                this.DanhSachLenh = (
                    await this.$store.dispatch("Lenh/layDSLenhChuaThucHien", query)
                ).Store;
            } catch (ex) {
                console.log("🚀 ex", ex);
                toast.message = "Lỗi hệ thống!";
                this.emitter.emit("onToast", toast);
            }
        },
        async ChonLenhChuyen(item) {
            this.LenhDangChon = item;
            let SuDungDichVu = this.$Core.AuthApi.TokenParsed.SuDungDichVu || null;
            if (SuDungDichVu) {
                let arrDichVu = SuDungDichVu.split(";");
                if (arrDichVu.some((e) => e == this.$t("StringCheck.SuDungVe"))) {
                    this.LayDanhSachHanhKhachMuaVeTrenXe();
                } else {
                    this.DanhSachDonHang = [];
                    this.ThongBaoXacNhanChuyenLenh();
                }
            }
        },
        ThongBaoXacNhanChuyenLenh() {
            this.ParamThongBao.message = `Bạn có chắc chắn muốn chuyển sang lệnh ${this.LenhDangChon.maLenh}?`;
            this.dialogXacNhanChuyenLenh = true;
        },
        XacNhanChuyenLenh(param) {
            if (!param) {
                this.dialogXacNhanChuyenLenh = false;
                this.dialogDanhSachVeChuyenLenh = false;
                return;
            }
            this.LaiXeChuyenDoiChuyenDi();
        },
        async LayDanhSachHanhKhachMuaVeTrenXe() {
            let toast = {
                message: "",
                type: "error",
                visible: true,
            };
            try {
                let idLenhDienTu =
                    this.$route.query.idLenhDienTu ||
                    "00000000-0000-0000-0000-000000000000";
                let rs = await this.$store.dispatch(
                    "Lenh/LayDanhSachHanhKhachMuaVeTrenXe",
                    idLenhDienTu,
                );
                if (rs.Data.status == true) {
                    this.DanhSachHanhKhachMuaVe = rs.Data.data;
                    if (this.DanhSachHanhKhachMuaVe.length == 0) {
                        this.DanhSachDonHang = [];
                        this.ThongBaoXacNhanChuyenLenh();
                    } else {
                        this.dialogDanhSachVeChuyenLenh = true;
                    }
                } else {
                    toast.message = rs.Data.message;
                    this.emitter.emit("onToast", toast);
                }
            } catch (ex) {
                console.log("🚀 ex", ex);
                toast.message = "Lỗi hệ thống!";
                this.emitter.emit("onToast", toast);
            }
        },
        async LaiXeChuyenDoiChuyenDi() {
            let toast = {
                message: "",
                type: "error",
                visible: true,
            };
            try {
                let idLenhDienTu =
                    this.$route.query.idLenhDienTu ||
                    "00000000-0000-0000-0000-000000000000";
                let DanhSachDonHang = [];
                this.DanhSachDonHang.forEach((e) => {
                    if (!DanhSachDonHang.some((x) => x == e)) {
                        DanhSachDonHang.push(e);
                    }
                });
                let query = {
                    idLenhCu: idLenhDienTu,
                    idLenhMoi: this.LenhDangChon.guidLenh,
                    idDonHangs: DanhSachDonHang,
                    ToaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$store.dispatch("Lenh/LaiXeChuyenDoiChuyenDi", query);
                if (rs.Data.status == true) {
                    toast.type = "success";
                    toast.message = "Chuyển đổi lệnh thành công!";
                    this.dialogXacNhanChuyenLenh = false;
                    this.dialogDanhSachVeChuyenLenh = false;
                    this.$router.push({
                        path: "/tabs/LenhDienTu",
                        query: {
                            isChiTiet: true,
                            guidLenh: this.LenhDangChon.guidLenh,
                        },
                    });
                } else {
                    toast.message = "Chuyển đổi lệnh thất bại! " + rs.Data.message;
                }
                this.emitter.emit("onToast", toast);
            } catch (ex) {
                console.log("🚀 ex", ex);
                toast.message = "Lỗi hệ thống!";
                this.emitter.emit("onToast", toast);
            }
        },
        XemBanTheHienLenh(item) {
            this.LenhDangChon = item;
            this.dialogBanTheHienLenh = true;
        },
        clearData() {
            this.NgayLoc = new Date();
            this.DanhSachLenh = [];
            this.DanhSachHanhKhachMuaVe = [];
            this.DanhSachDonHang = [];
            this.dialogBanTheHienLenh = false;
            this.LenhDangChon = {};
            this.dialogDanhSachVeChuyenLenh = false;
            this.dialogXacNhanChuyenLenh = false;
        },
    },
    mounted() {
        onIonViewWillLeave(() => {
            this.clearData();
        });
    },
};
</script>

<style scoped></style>
